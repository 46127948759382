import { graphql, PageProps, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/Layout/Layout";
import Box from "../components/Box/Box";
import Seo from "../components/Seo";
import { Paragraph, Paragraph2, Title } from "../components/Styled/Text";
import { get } from "../constants/theme";
import { clampBuilder } from "../helpers/sizeCalc";
import useWindowSize from "../hooks/useWindowSize";
import { ProjectsPageQuery } from "../types/pages/projects";
import LinkArrowBtn from "../components/LinkArrowBtn";
import { Routes } from "../routes";
import Wrapper from "../components/Wrapper";
import GridSection from "../components/GridSection";
import GatsbyImageWithArtDirection from "../components/GatsbyImageWithArtDirection";
import { paddingBottom } from "styled-system";

interface Props extends PageProps {
  data: ProjectsPageQuery;
}

const Projects = ({ data, location }: Props) => {
  const { height } = useWindowSize();

  return (
    <>
      <Seo
        title={data.translationsJson.pages.projects.seo.title}
        description={data.translationsJson.pages.projects.seo.description}
        keywords={data.translationsJson.pages.projects.seo.keywords}
        pathname={location.pathname}
        metaImage={
          data.translationsJson.pages.projects.seo.image.childImageSharp
            .gatsbyImageData
        }
      />
      <Layout pathname={location.pathname}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            minHeight: height,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              zIndex: 10,
              color: "#ffff",
              userSelect: "none",
              fontFamily: "custom_42263",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                fontFamily: "custom_42266",
                fontSize: "21px",
                fontWeight: 400,
                color: get("palette.text.dark_gold"),
                marginBottom: "30px",
                visibility:"hidden",
              }}
              as="span"
            >
              {data.translationsJson.pages.projects.header.subtitle}
            </Box>
            {data.translationsJson.pages.projects.header.title.map((t, i) => (
              <Title variant="bigHero" key={i} sx={{ color: "white" }}>
                {t}
              </Title>
            ))}

            <Paragraph2
              variant="hero"
              sx={{
                color: "#fff",
                opacity: 0.85,
                maxWidth: [
                  "90%",
                  clampBuilder(599, 1680, 19.625, 44.625),
                  clampBuilder(599, 1680, 19.625, 44.625),
                  clampBuilder(599, 1680, 19.625, 44.625),
                ],
              }}
            >
              {data.translationsJson.pages.projects.header.description}
            </Paragraph2>
          </Box>

          <GatsbyImageWithArtDirection
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              width: "100%",
              height: "100%",
            }}
            imgStyle={{ objectFit: "cover" }}
            desktopImage={data.headerDesktop.childImageSharp.gatsbyImageData}
            mobileImage={data.headerMobile.childImageSharp.gatsbyImageData}
            alt=""
          />
        </Box>
        <Box
          sx={{
            background: get("palette.background.gold"),
            paddingBottom:["100px","140px","240px","240px"],
            paddingTop:["100px","140px","240px","240px"]}}
        >
        <Box
          sx={{
            
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            
            textAlign: "center",
            paddingLeft: "7.5%",
            paddingRight: "7.5%",
            paddingBottom: ["100px","140px","240px","240px"]
          }}
        >
          {data.translationsJson.pages.projects.values.header.title.map(
            (t, i) => (
              <Title key={i} variant="section">
                {t}
              </Title>
            )
          )}
          
          <Paragraph
            sx={{
              width: [
                "100%",
                "400px",
                "500px",
                "700px",
              ],
              textAlign: "center",
              marginTop: "56px"
            }}
          >
            {data.translationsJson.pages.projects.values.header.description}
          </Paragraph>
        </Box>
        <Wrapper>
          <GridSection
            firstChildrenType="textBox"
            secondChildrenType="imageBox"
          >
            <>
              <Title variant="paragraph" sx={{textAlign: "center",}}>
                {data.translationsJson.pages.projects.values.items[0].title}
              </Title>
              <Paragraph
                sx={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "56px" 
                }}
              >
                {
                  data.translationsJson.pages.projects.values.items[0]
                    .description
                }
              </Paragraph>
            </>
            <>
              <Box
                as={GatsbyImage}
                alt={
                  data.translationsJson.pages.projects.values.items[0].imageAlt
                }
                image={
                  data.translationsJson.pages.projects.values.items[0].image
                    .childImageSharp.gatsbyImageData
                }
              />
              <Paragraph
                sx={{
                  fontFamily: "custom_42266",
                  fontWeight: 400,
                  fontSize: "12px",
                  color: "rgb(184,171,148)",
                  margin: "0 auto",
                  textAlign: "left"
                }}
              >
                {
                  data.translationsJson.pages.projects.values.items[0]
                    .imageTitle
                }
              </Paragraph>
            </>
          </GridSection>


        </Wrapper>
        </Box>


        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: ["auto", height - 300, height - 300, height - 300],
            marginTop: ["100px","140px","240px","240px"],
            textAlign: "center",
            padding: "0 7.5%",
          }}
        >
          {data.translationsJson.pages.projects.sundial.header.title.map(
            (t, i) => (
              <Title key={i} variant="section">
                {t}
              </Title>
            )
          )}
          <Box
          sx={{
            marginTop:"56px",
            marginBottom:"56px"
          }}>
          {data.translationsJson.pages.projects.sundial.header.description.map(
            (p, i) => (
          <Paragraph 
          key={i}
            sx={{
              width: [
                "100%",
                clampBuilder(599, 1240, 14.875, 55),
                clampBuilder(599, 1240, 14.875, 55),
                clampBuilder(599, 1240, 14.875, 55),
              ],
              marginBottom: "15px"
            }}
          >
            {p}
           
          </Paragraph>
            )
          )}
          </Box>
        </Box>
        <Wrapper>
          <Box
            sx={{
              width: "100%",
              minHeight: ["200px", "600px", "800px", "800px"],
              margin: "20px auto auto auto",
              marginBottom: ["80px", "80px", "20px", "20px"],
              WebkitOverflowScrolling: "touch",
              maxWidth: "2560px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                
                
              }}
            >
              <video
                controls
                width="100%"
                preload="none"
                poster={
                  data.translationsJson.pages.projects.sundial.header.poster
                    .publicURL
                }
              >
                <source
                  src="https://media.ipf1721.ru/video/IPF_Solar_7.mp4"
                  type="video/mp4"
                />
              </video>
            </Box>
          </Box>
        </Wrapper>
        <Wrapper>
          <GridSection
            firstChildrenType="imageBox"
            secondChildrenType="textBox"
          >

            <>
              <Box
                as={GatsbyImage}
                alt={
                  data.translationsJson.pages.projects.sundial.items[0].imageAlt
                }
                image={
                  data.translationsJson.pages.projects.sundial.items[0].image
                    .childImageSharp.gatsbyImageData
                }
              />
            </>
            <>
            {data.translationsJson.pages.projects.sundial.items[0].title.map(
            (t, i) => (
              <Title key={i} variant="paragraph" sx={{textAlign: "center",}}>
                {t}
              </Title>
            )
          )}
          { data.translationsJson.pages.projects.sundial.items[0].description.map(
            (t, i) => (
          <Paragraph 
          key={i}
            sx={{
              width: [
                "100%",
                clampBuilder(599, 1680, 14.875, 41),
                clampBuilder(599, 1680, 14.875, 41),
                clampBuilder(599, 1680, 14.875, 41),
              ],
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            {t}
           
          </Paragraph>
            )
          )}
            </>
          </GridSection>
          </Wrapper>
          <Wrapper>
          <Box
            sx={{
              width: "100%",
              margin: "20px auto auto auto",
              marginBottom: ["80px", "20px", "20px", "20px"],
              WebkitOverflowScrolling: "touch",
              maxWidth: "2560px",
            }}
          >
           
            <Box
              sx={{
                width: "100%",
                marginBottom: ["100px","140px","240px","240px"],
                marginTop: ["100px","140px","240px","240px"]
              }}
              id="video"
            >
              <video
                controls
                width="100%"
                height="100%"
                preload="none"
                poster={
                  data.translationsJson.pages.projects.sundial.header.poster2
                    .publicURL
                }
              >
                <source
                  src="https://media.ipf1721.ru/video/Raketa-Sundial-Peterhof.mp4"
                  type="video/mp4"
                />
              </video>
            </Box>
            
          </Box>
        </Wrapper>
        <Wrapper>
          <GridSection
            firstChildrenType="textBox"
            secondChildrenType="imageBox"
          >

            <>
            {data.translationsJson.pages.projects.sundial.items[1].title.map(
            (t, i) => (
              <Title key={i} variant="paragraph" sx={{textAlign: "center",}}>
                {t}
              </Title>
            )
          )}
          { data.translationsJson.pages.projects.sundial.items[1].description.map(
            (p, i) => (
          <Paragraph 
          key={i}
            sx={{
              width: [
                "100%",
                clampBuilder(599, 1680, 14.875, 41),
                clampBuilder(599, 1680, 14.875, 41),
                clampBuilder(599, 1680, 14.875, 41),
              ],
              marginBottom: "10px",
              textAlign: "center"
            }}
          >
            {p}
           
          </Paragraph>
            )
          )}
            </>
            <>
              <Box
                as={GatsbyImage}
                alt={
                  data.translationsJson.pages.projects.sundial.items[1].imageAlt
                }
                image={
                  data.translationsJson.pages.projects.sundial.items[1].image
                    .childImageSharp.gatsbyImageData
                }
              />
            </>
          </GridSection>
        </Wrapper>
        <Wrapper 
          sx={{
            marginTop:["100px","140px","240px","240px"],
            marginBottom:["100px","140px","240px","240px"]
          }}>
          <GridSection
            firstChildrenType="imageBox"
            secondChildrenType="textBox"
            
          >
            <>
              <Box
                as={GatsbyImage}
                alt={
                  data.translationsJson.pages.projects.sundial.items[2].imageAlt
                }
                image={
                  data.translationsJson.pages.projects.sundial.items[2].image
                    .childImageSharp.gatsbyImageData
                }
              />
            </>
            <>
            {data.translationsJson.pages.projects.sundial.items[2].title.map(
            (t, i) => (
              <Title key={i} variant="paragraph" sx={{textAlign: "center",}}>
                {t}
              </Title>
            )
          )}
          { data.translationsJson.pages.projects.sundial.items[2].description.map(
            (p, i) => (
          <Paragraph 
          key={i}
            sx={{
              width: [
                "100%",
                clampBuilder(599, 1680, 14.875, 41),
                clampBuilder(599, 1680, 14.875, 41),
                clampBuilder(599, 1680, 14.875, 41),
              ],
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            {p}
           
          </Paragraph>
            )
          )}
            </>
          </GridSection>

        </Wrapper>
        <Box
          sx={{
            marginBottom: "80px",
            marginTop: "40px",
          }}
        >
          <LinkArrowBtn
            title={data.translationsJson.pages.projects.link}
            to={Routes.COLLECTION}
            as={Link}
          />
        </Box>
      </Layout>
    </>
  );
};

export default React.memo(Projects);

export const projectsQuery = graphql`
  query ProjectsQuery {
    headerDesktop: file(
      relativeDirectory: { eq: "pages/projects/header" }
      name: { eq: "desktop" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }
    headerMobile: file(
      relativeDirectory: { eq: "pages/projects/header" }
      name: { eq: "mobile" }
    ) {
      id
      relativeDirectory
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    translationsJson {
      pages {
        projects {
          link
          seo {
            title
            description
            keywords
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          header {
            subtitle
            title
            description
          }
          sundial {
            header {
              title
              description
              poster {
                publicURL
              }
              poster2 {
                publicURL
              }
            }
            items {
              title
              description
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              imageAlt
            }
            end {
              title
              description
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              imageAlt
              imageTitle
            }
          }
          watches {
            header {
              title
              description
            }
            items {
              title
              description
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              imageAlt
            }
          }
          values {
            header {
              title
              description
              
            }
            items {
              title
              description
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              imageAlt
              imageTitle
            }
          }
        }
      }
    }
  }
`;
